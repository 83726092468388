<template>
  <div>
    <div class="border-b border-grey-2">
      <div class="columns pb-2">
        <div class="column mt-2 mr-3">
          <b-button
            type="is-primary"
            class="is-pulled-right"
            @click="openNewModuleModal()"
          >
            <b-icon
              icon="plus"
              class="mr-2"
              size="is-small"
            />New user
          </b-button>
        </div>
      </div>
    </div>

    <div
      v-for="(userData, index) in users"
      :key="index"
      class="py-5 border-b border-grey-2"
    >
      <div
        v-if="!isUserFetchDone"
        class="columns is-vcentered px-5"
      >
        <div class="is-10 column">
          <b-skeleton height="30" />
          <b-skeleton height="50" />
        </div>
        <div class="is-2 column">
          <b-skeleton height="30" />
        </div>
      </div>

      <div
        v-else
        class="columns is-vcentered px-5"
      >
        <div
          class="media"
          style="width: 100%"
        >
          <b-image
            class="is-48x48 media-left"
            :src="require('@/assets/user-placeholder.png')"
            alt="A random image"
            rounded
          />
          <div
            class="media-content"
            style="flex: 1"
          >
            <h1 class="is-size-4">
              {{ userData.first_name }} {{ userData.last_name }}
            </h1>
            <ul
              class="is-size-7"
              style="overflow: hidden"
            >
              <li
                style="float: left"
                class="mr-5"
              >
                <strong>Email: </strong>{{ userData.email }}
              </li>
              <li
                style="float: left"
                class="mr-5"
              >
                <strong>Role: </strong>{{ userData.role.toUpperCase() }}
              </li>
            </ul>
          </div>

          <div class="is-pulled-right">
            <b-dropdown
              aria-role="list"
              expanded
            >
              <template #trigger="{ active }">
                <b-button
                  expanded
                  label="Options"
                  :icon-right="active ? 'menu-up' : 'menu-down'"
                />
              </template>
              <b-dropdown-item
                aria-role="listitem"
                @click="goToEditUser(userData.id)"
              >
                Edit
              </b-dropdown-item>
              <b-dropdown-item
                class="has-text-danger"
                aria-role="listitem"
                @click="deleteUser(userData.id)"
              >
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <BaseModal
      v-model="editModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Profile"
      aria-modal
    >
      <CardPopup
        title="Insert formula"
        class="w-800"
        @hide="editModalActive = false"
      >
        <template #body>
          <b-field label="First Name">
            <b-input
              v-model="user.firstName"
              type="text"
              placeholder="e.g. John"
              required
            />
          </b-field>
          <b-field label="Last Name">
            <b-input
              v-model="user.lastName"
              type="text"
              placeholder="e.g. Doe"
              required
            />
          </b-field>
          <b-field label="Email">
            <b-input
              v-model="user.email"
              type="email"
              placeholder="e.g. john.doe@example.com"
              required
            />
          </b-field>
          <b-field label="New password">
            <b-input
              v-model="user.password"
              type="password"
              placeholder="e.g. john.doe@example.com"
              required
              password-reveal
            />
          </b-field>
          <b-field label="Role">
            <b-select
              v-model="user.role"
              placeholder="Select role"
              expanded
            >
              <option value="admin">
                Admin
              </option>
              <option value="user">
                User
              </option>
            </b-select>
          </b-field>
          <b-field label="Organisation">
            <b-taginput
              v-model="user.organisationIds"
              autocomplete
              :data="organisationList"
              field="name"
              type="is-primary"
              :open-on-focus="true"
            />
          </b-field>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="editModalActive = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="updateUser()"
            >
              Update
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
  </div>
</template>

<script>
import { defineAsyncComponent } from '@vue/composition-api';
import { fetchOrganisationsService } from '@/services/user-service/organisationRequests';
import { deleteUserService, fetchUserByIdService, fetchUsersService, updateUserService } from '@/services/user-service/userRequests';
import { useUserStore } from '@/modules/users/store/userStore';

const userStore = useUserStore();

export default {
    components: {
        BaseModal: defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue')),
        CardPopup: defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue'))
    },
    data: () => ({
        isUserFetchDone: false,
        editModalActive: false,
        users: [],
        user: {
            id: null,
            firstName: '',
            lastName: '',
            role: '',
            email: '',
            password: '',
            organisationIds: []
        },
        organisationList: []
    }),
    mounted() {
        this.fetchUsers();
        this.fetchOrganisations();
    },
    methods: {
        async fetchOrganisations () {
            try {
                const { data: { data: organistionData } } = await fetchOrganisationsService();
                this.organisationList = organistionData;
            } catch (err) {
                console.error(err);
            }
        },
        async fetchUsers () {
            try {
                const { data: { data: usersData } } = await fetchUsersService();
                this.users = usersData;
                this.isUserFetchDone = true;
            } catch (err) {
                console.log(err);
            }
        },
        async deleteUser(id) {
            try {
                await deleteUserService(id);
                this.fetchUsers();
            } catch (err) {
                console.error(err);
            }
        },
        openNewModuleModal() {
            userStore.setNewUserModalActive(true);
        },
        async goToEditUser(id) {
            try {
                const { data: { data: userData } } = await fetchUserByIdService(id);
                this.user.role = userData.role;
                this.user.email = userData.email;
                this.user.id = userData.id;
                this.user.firstName = userData.first_name;
                this.user.lastName = userData.last_name;
                this.user.organisationIds = userData.organisationIds;
                this.user.password = '';
                this.editModalActive = true;
            } catch (err) {
                console.error(err);
            }
        },
        async updateUser() {
            try {
                const organisationIds = [];
                this.user.organisationIds.forEach(item => {
                    organisationIds.push(item.id);
                });
                await updateUserService(this.user.id, {
                    email: this.user.email,
                    password: this.user.password,
                    role: this.user.role,
                    first_name: this.user.firstName,
                    last_name: this.user.lastName,
                    organisationIds: organisationIds
                });
                this.fetchUsers();
                this.editModalActive = false;
            } catch (err) {
                CanvasPattern.error(err);
            }
        }
    }
};
</script>
